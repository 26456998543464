<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2>{{ $t("Header.AboutUs.Structure") }}</h2>
      </div>
    </section>

    <!-- End Slider -->

    <section id="mt-blog" class="">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 col-sm-12 width70"
            v-for="(hodim, i) in hodimlar"
            :key="i"
          >
            <div class="blog_post_sec">
              <div class="blog-post_wrapper">
                <div class="blog-post-image lazyload blur-up">
                  <img
                    :data-src="BaseUrl + 'storage/' + hodim.image"
                    style="height: 100%"
                    alt="image"
                    class="img-responsive center-block post_img lazyload blur-up"
                  />
                </div>
                <div
                  class="post-content"
                  style="display: flex; flex-flow: row wrap"
                >
                  <h2 class="entry-title" style="font-size: 22px">
                    <span class="">{{ hodim[`fname_${$i18n.locale}`] }}</span>
                    <span v-if="$i18n.locale == 'cyril'">{{
                      hodim.fname_kiril
                    }}</span>
                  </h2>
                  <div class="item-meta" style="width: 100%">
                    <span style="font-size: 17px">{{
                      hodim[`position_${$i18n.locale}`]
                    }}</span>
                    <span
                      style="font-size: 17px"
                      v-if="$i18n.locale == 'cyril'"
                      >{{ hodim.position_kiril }}</span
                    >
                  </div>
                  <div
                    class="item-meta"
                    style="font-size: 18px; width: 100%; align-self: flex-end"
                  >
                    <i class="fa fa-phone"></i>
                    <span class="author-name">{{
                      hodim[`phone_${$i18n.locale}`]
                    }}</span>
                    <span class="author-name" v-if="$i18n.locale == 'cyril'">{{
                      hodim.phone_kiril
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "../apis";

export default {
  name: "Structure",
  data() {
    return {
      BaseUrl: "https://backend.scocenter.uz/",
      hodimlar: {},
      load: true,
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/staff").then((response) => {
          setTimeout(() => (this.load = false), 500),
            (this.hodimlar = response.data.staff.sort((a, b) => a.id - b.id));
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
.breadcrumb-outer {
  padding: 40px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 15px !important;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
#mt-blog .blog-post_wrapper {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  overflow: hidden;
  height: 270px !important;
  height: inherit;
  box-shadow: 0 0 15px #cccccc30;
  border: 5px solid #fff;
}
#mt-blog .blog-post_wrapper .blog-post-image {
  width: 45%;
  position: relative;
  overflow: hidden;
}
#mt-blog .blog-post_wrapper .post-content {
  width: 55%;
  padding: 12px 12px 12px;
  text-align: center;
  height: 270px;
}
#mt-blog .blog-post_wrapper .post-content {
  position: relative;
  z-index: 2;
}
#mt-blog .blog-post_wrapper h2 {
  max-height: 3em;
  font-size: 24px;
}

#mt-blog .blog-post_wrapper h2 {
  margin-bottom: 15px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
}
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
@media (max-width: 500px) {
  #mt-blog .blog-post_wrapper {
    flex-direction: column !important;
    height: 450px !important;
    width: 100% !important;
  }
  .blog-post-image {
    width: 100% !important;
  }
  img {
    width: 100% !important;
  }
  .post-content {
    width: 100% !important;
    display: initial !important;
  }
}
</style>